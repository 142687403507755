import {
  InvestorReportsDetailsData,
  getCardTitleColor,
} from '@/pages/InvestorReporting/utils/investorReports';
import { Badge, Button } from 'react-daisyui';
import {
  faEye,
  faLock,
  faLockOpen,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState, useEffect } from 'react';
import { Client, InvestorReportCardDTO, File } from '@types';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { InvestorReportAnswer } from '@/pages/InvestorReporting/components/Clients/InvestorReportAnswer';
import { Spinner } from '@/components/Spinner';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import { patch, post } from '@/utils/api';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { ToastContainer } from 'react-toastify';
import { InvestorReportCardButton } from '@/pages/InvestorReporting/components/InvestorReportCardButton';
import { FilePreviewModal } from '@/components/FileBrowser/modals';
import { fetchUrl, processDownloadUrl } from '@/utils/teamsFileDownload';
import { useRollbar } from '@rollbar/react';
import {
  ReportsFrequencies,
  ReportStatuses,
} from '../../../../../shared/constants';
import useLogEvent from '../../../utils/logEvent';

const finalCardStatus = [ReportStatuses.closed, ReportStatuses.deleted];

export function InvestorReportsInfo({
  clientId,
  investorReportsData,
  refreshData,
}: {
  clientId: number;
  investorReportsData: InvestorReportsDetailsData;
  refreshData: () => Promise<void>;
}) {
  const rollbar = useRollbar();
  const { logEvent } = useLogEvent();
  const { auth }: IUserContext = useUserContext();
  const [previewFile, setPreviewFile] = useState<boolean>(false);
  const [fileItem, setFileItem] = useState<File | undefined>(undefined);
  const [reportId, setReportId] = useState<number | undefined>(
    investorReportsData.selected_investor_report?.id
  );
  const [cardToAnswer, setCardToAnswer] = useState<
    InvestorReportCardDTO | undefined
  >(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const [cardToClose, setCardToClose] = useState<
    InvestorReportCardDTO | undefined
  >(undefined);
  const [cardToReopen, setCardToReopen] = useState<
    InvestorReportCardDTO | undefined
  >(undefined);
  const [sendNotification, setSendNotification] = useState<boolean>(false);
  const [isDataRefreshed, setIsDataRefreshed] = useState(false);
  const frequencies = [
    { index: 0, label: ReportsFrequencies.monthly },
    { index: 1, label: ReportsFrequencies.quarterly },
    { index: 2, label: ReportsFrequencies.annual },
  ];

  const enabledFrequencies = useMemo(
    () => investorReportsData.investor_reports.map((rep) => rep.frequency),
    [investorReportsData.investor_reports]
  );

  const report = useMemo(() => {
    if (reportId) {
      return investorReportsData.investor_reports.find(
        (rep) => rep.id === reportId
      );
    }
    return undefined;
  }, [investorReportsData.investor_reports, reportId]);

  const onChangeFrequency = (frequency: string) => {
    setCardToAnswer(undefined);
    setReportId(
      investorReportsData.investor_reports.find(
        (rep) => rep.frequency === frequency
      )?.id || undefined
    );
  };

  const isReportComplete = useMemo(() => {
    if (report) {
      const isReportNotClosed = report.status !== ReportStatuses.closed;
      const allCardAnswered =
        report.investor_report_cards?.every(
          (card) =>
            card.status === ReportStatuses.completed ||
            card.status === ReportStatuses.closed
        ) || false;
      return isReportNotClosed && allCardAnswered;
    }
    return false;
  }, [report]);

  useEffect(() => {
    if (isDataRefreshed && isReportComplete) {
      setSendNotification(true);
      setIsDataRefreshed(false);
    }
  }, [isDataRefreshed, isReportComplete]);

  const downloadFile = (file: File) => {
    fetchUrl(file.id, file.teamId)
      .then(async (url) => {
        await processDownloadUrl(url, file.name);
        await logEvent(file, clientId, auth?.role as string);
      })
      .catch((err) => rollbar.log(err as Error));
  };

  const setFileToPreview = (value: File) => {
    setPreviewFile(true);
    setFileItem(value);
  };

  const handleCardChangeStatus = async (
    card: InvestorReportCardDTO,
    status: ReportStatuses.closed | ReportStatuses.open
  ) => {
    setIsSaving(true);
    try {
      await patch(
        `investorReports/investorReportsCard/client/${clientId}/status/${
          card?.id as number
        }`,
        {
          status,
        }
      );
      alertMessageSuccess('Card updated successfully');
      setCardToClose(undefined);
      setCardToReopen(undefined);
      await refreshData();
      setIsDataRefreshed(true);
    } catch {
      alertErrorMessage('Failed to update card, please try again later');
    }
    setIsSaving(false);
  };

  const onHandleMarkAsClosed = async () => {
    await handleCardChangeStatus(
      cardToClose as InvestorReportCardDTO,
      ReportStatuses.closed
    );
  };

  const onHandleReopenCard = async () => {
    await handleCardChangeStatus(
      cardToReopen as InvestorReportCardDTO,
      ReportStatuses.open
    );
  };

  const onHandleCardUpdate = async () => {
    await refreshData();
    setCardToAnswer(undefined);
    setIsDataRefreshed(true);
  };

  const selectCard = (card: InvestorReportCardDTO) => {
    setCardToAnswer(undefined);
    setTimeout(() => {
      setCardToAnswer(card);
    }, 50);
  };

  const onHandleSendUpdates = async () => {
    await post(`investorReports/notify/${report?.id as number}`, {});
    await refreshData();
    setCardToAnswer(undefined);
    setSendNotification(false);
    alertMessageSuccess('Notification sent successfully');
  };

  const processFileError = () => {
    alertErrorMessage(
      'This file has been updated. Please wait a few minutes and refresh your page to access the latest version.'
    );
  };

  if (!report) {
    return <span>No report</span>;
  }

  return (
    <div className='flex'>
      <ToastContainer />
      {cardToClose ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={onHandleMarkAsClosed}
          onCloseAction={() => setCardToClose(undefined)}
          showModal={!!cardToClose}
          modalMessage='Are you sure you want to mark this card as closed?'
        />
      ) : null}
      {cardToReopen ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={onHandleReopenCard}
          onCloseAction={() => setCardToReopen(undefined)}
          showModal={!!cardToReopen}
          modalMessage='Are you sure you want to reopen this card?'
        />
      ) : null}
      {sendNotification ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={onHandleSendUpdates}
          onCloseAction={() => setSendNotification(false)}
          showModal={sendNotification}
          // eslint-disable-next-line max-len
          modalMessage={`You have completed all open requests for this investor for the current time period. Click 'Confirm' to notify the investor or 'Cancel' to skip notification.`}
        />
      ) : null}
      <div
        className={`${isSaving ? 'spinner-container' : 'hidden'}`}
        id='spinner-total'
        data-testid='spinner-transaction'
      >
        <Spinner className='spinner' />
      </div>
      <div className='w-[50%]'>
        <div className='p-4'>
          Investor Name:
          <span className='ml-2 font-bold'>
            {investorReportsData.investor_name}
          </span>
        </div>
        <div className='pl-4 flex'>
          {frequencies.map((data) => {
            return (
              <span key={`${data.index + 1}_frequency_span`}>
                <Button
                  size='sm'
                  onClick={() => onChangeFrequency(data.label)}
                  className={`${
                    data.label === report.frequency ? 'btn-active' : ''
                  }`}
                  key={`${data.index + 1}_frequency_button`}
                  disabled={!enabledFrequencies.includes(data.label)}
                >
                  {data.label}
                </Button>
              </span>
            );
          })}
        </div>

        <div className='p-4'>
          Period:
          <span className='ml-2 font-bold'>{report.period}</span>
        </div>

        <div className='flex flex-wrap w-full'>
          {report.investor_report_cards?.map((card) => (
            <div
              key={`card-${card.title}`}
              className={`items-center my-2 mx-2 shadow card rounded-lg 
              w-[45%] justify-center bg-gradient-to-r p-[1px] from-[#00a089] to-[#ff0080]`}
            >
              <div
                key={`card-${card.title}`}
                className='bg-[#1b1b1c] shadow-inner rounded h-full content-center'
              >
                <div className='mt-4 mx-4 text-center '>
                  <span className='font-bold'>{card.title}</span>
                  <div>
                    <span className='font-bold text-sm'>
                      Status:
                      <Badge
                        color={getCardTitleColor(card.status)}
                        size='md'
                        className='ml-2'
                      >
                        {card.status}
                      </Badge>
                    </span>
                  </div>
                  <div>
                    <span>Description: {card.description}</span>
                  </div>
                  <div className='flex justify-center my-2'>
                    {card.status === ReportStatuses.open &&
                    auth?.isClient &&
                    !finalCardStatus.includes(report.status) ? (
                      <div className='flex'>
                        <InvestorReportCardButton
                          label='Mark as Closed'
                          icon={faLock}
                          color='error'
                          onClick={() => {
                            setCardToAnswer(undefined);
                            setCardToClose(card);
                          }}
                          data-testid={`mark-closed-${card.id as number}`}
                        />
                        <InvestorReportCardButton
                          label='Answer request'
                          icon={faPencil}
                          color='error'
                          onClick={() => {
                            selectCard(card);
                          }}
                          data-testid={`answer-card-${card.id as number}`}
                        />
                      </div>
                    ) : null}
                    {card.status === ReportStatuses.closed &&
                    auth?.isClient &&
                    !finalCardStatus.includes(report.status) ? (
                      <InvestorReportCardButton
                        label='Reopen Card'
                        icon={faLockOpen}
                        color='accent'
                        onClick={() => {
                          setCardToAnswer(undefined);
                          setCardToReopen(card);
                        }}
                        data-testid={`reopen-${card.id as number}`}
                      />
                    ) : null}
                    {card.status === ReportStatuses.completed ? (
                      <div className='flex'>
                        <InvestorReportCardButton
                          label='View Response'
                          icon={faEye}
                          color='accent'
                          onClick={() => selectCard(card)}
                          data-testid={`view-response-${card.id as number}`}
                        />
                        <InvestorReportCardButton
                          label='Edit Response'
                          icon={faLockOpen}
                          color='accent'
                          onClick={() => {
                            const copyCard = { ...card };
                            copyCard.isBeingEdited = true;
                            selectCard(copyCard);
                          }}
                          data-testid={`edit-response-${card.id as number}`}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {auth?.isClient ? (
          <div className='flex flex-wrap w-full ml-4 justify-center'>
            <div className='w-[45%]'>
              <Button
                type='submit'
                title='login'
                color='accent'
                size='sm'
                className='btn btn-accent bg-transparent my-2 mx-4'
                data-testid='send-update-button'
                disabled={!isReportComplete}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={() => setSendNotification(true)}
              >
                Click here to send updates
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      {cardToAnswer ? (
        <InvestorReportAnswer
          investorReportsCard={cardToAnswer}
          client={report.client as Client}
          setSpinner={(value) => {
            setIsSaving(value);
          }}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onCardUpdated={() => onHandleCardUpdate()}
          setFileToPreview={setFileToPreview}
        />
      ) : null}

      {previewFile && fileItem ? (
        <FilePreviewModal
          isOpen={previewFile}
          fileItem={fileItem}
          onClose={() => {
            setPreviewFile(false);
          }}
          onDownload={() => downloadFile(fileItem)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onError={() => processFileError()}
        />
      ) : null}
    </div>
  );
}
