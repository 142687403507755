/* eslint-disable @typescript-eslint/no-misused-promises */
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IUserContext, useUserContext } from '@/context/UserContext';
import { MENU_BY_USER, getIconDefinitionByName } from '@/components/Nav/utils';
import { MenuOption } from '@/types/menu-options';
import useCustomTabsPermissions from '@/hooks/useCustomTabsPermissions';
import useInvestor from '@/hooks/useInvestor';
import {
  faChevronDown,
  faChevronRight,
  faClock,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import MainMenuItem from '@/components/Nav/MainMenuItem';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-daisyui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CustomTabItem,
  JWTUser,
  Client,
  ExtendedCustomTabItem,
  UserRequestAccess,
} from '@types';
import 'react-toastify/dist/ReactToastify.css';
import AddingCompanyModal from '@/components/Nav/AddingCompanyModal';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import { post } from '@/utils/api';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { useRollbar } from '@rollbar/react';

function AddNewCompanyButton({ auth }: { auth: JWTUser }) {
  const [showModal, setShowModal] = useState(false);
  if (!auth.isInvestor) return null;
  return (
    <div className='mt-2 w-[90%] justify-center'>
      <Button
        type='submit'
        title='login'
        color='accent'
        size='sm'
        className='btn btn-accent bg-transparentp-2 m-2'
        data-testid='add-new-company-button'
        onClick={() => setShowModal((prev) => !prev)}
      >
        <FontAwesomeIcon icon={faPlusCircle} className='mr-1' />
        add new company
      </Button>
      {showModal ? (
        <AddingCompanyModal
          showModalStatus={showModal}
          onClose={() => setShowModal(false)}
          auth={auth}
        />
      ) : null}
    </div>
  );
}
function InvestorsMenu() {
  const {
    impersonatingAccountId,
    impersonate,
    auth,
    setimpersonatingAccountId,
    clearImpersonationSession,
  }: IUserContext = useUserContext();

  const rollbar = useRollbar();
  const [customTabInfo, setCustomTabInfo] = useState<CustomTabItem[] | null>(
    null
  );
  const [menuOptions, setMenuOptions] = useState<MenuOption[]>([]);
  const [userRequestAccess, setUserRequestAccess] = useState<
    UserRequestAccess | undefined
  >(undefined);
  const [pendingClientsOptions, setPendingClientsOptions] = useState<
    MenuOption[]
  >([]);
  const { useStartups } = useInvestor();
  const { data: startups, isLoading: isLoadingStartups } = useStartups();
  const { useCustomTabs } = useCustomTabsPermissions();
  const navigate = useNavigate();
  const [impersonateMenuOptions, setImpersonateMenuOptions] = useState<
    MenuOption[]
  >([]);

  const clientId = auth?.isClient
    ? auth?.clients?.[0]?.id
    : impersonatingAccountId || undefined;

  const { data: customTabs, isLoading } = useCustomTabs(
    auth?.id as number,
    clientId
  );

  // Function to handle action (only for the first render)
  const onStartupAction = (
    startupId: number,
    startupName: string,
    url: string
  ) => {
    if (impersonate && auth?.authorizedClientIds.includes(startupId)) {
      impersonate(startupId, startupName);
    } else {
      clearImpersonationSession();
    }
    navigate(url, { replace: true });
  };

  useEffect(() => {
    if (customTabs) setCustomTabInfo(customTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, isLoading]);

  useEffect(() => {
    const userFirmsIds = auth?.firms?.map((firm) => firm.id) || [];

    const filteredCustomTabs = (
      (customTabInfo as ExtendedCustomTabItem[]) || []
    ).filter((customTab) => {
      const tabAllowedFirms = (customTab.allowedFirms || []).map(
        (firm) => firm.firmId
      );
      const skippedUsers = (customTab.allowedFirms || []).flatMap((firm) =>
        firm.skippedUsers.map((userId) => userId)
      );
      return (
        (customTab.allowedUsers &&
          customTab.allowedUsers.includes(auth?.id as number)) ||
        (customTab.allowedFirms &&
          userFirmsIds.some((firm) => tabAllowedFirms.includes(firm)) &&
          !skippedUsers.includes(auth?.id as number))
      );
    });
    const prepareCustomTabs: MenuOption[] = (filteredCustomTabs || []).map(
      (customTab: CustomTabItem) => ({
        icon: getIconDefinitionByName(customTab.icon) as IconProp,
        label: customTab.titleOfTab,
        url: `/custom-tab-option/${encodeURIComponent(
          customTab.titleOfTab
        )}?embeddedLink=${encodeURIComponent(
          customTab.embeddedLink
        )}&documentLink=${encodeURIComponent(customTab.documentLink)}`,
      })
    );
    // Function to generate menu options
    const getMenuOptions = () => {
      let options: MenuOption[];
      options = [];
      setImpersonateMenuOptions(
        [...MENU_BY_USER.clientInvestor, ...prepareCustomTabs] || []
      );

      // Remove the /account option for investors when impersonating
      if (impersonatingAccountId && auth?.role === 'investor') {
        options = options.filter((option) => option.url !== '/account');
      }

      return options;
    };
    const getPendingClientsMenuOptions = () => {
      return (
        (auth?.user_request_access?.map((client) => ({
          label: client.client_name,
          icon: faClock,
          url: `/home-investor/${client.client_id}`,
          subOptions: [],
          action: () => {
            setUserRequestAccess(client);
          },
        })) as MenuOption[]) || []
      );
    };
    const menu = getMenuOptions();
    const pendingClientsMenu = getPendingClientsMenuOptions();

    // Set the menu options without the action
    setMenuOptions(menu);
    setPendingClientsOptions(pendingClientsMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impersonatingAccountId, customTabInfo]);

  // Separate useEffect to handle adding startups to the menu
  useEffect(() => {
    if (
      auth?.role === 'investor' &&
      impersonateMenuOptions &&
      startups &&
      startups?.length > 0 &&
      !isLoadingStartups
    ) {
      const filteredStartups = startups
        ?.sort((a, b) => (a.client_name >= b.client_name ? 1 : -1))
        .filter(
          (startup) =>
            auth.isSuperAdmin || auth.authorizedClientIds.includes(startup.id)
        );

      const startupOptions = filteredStartups?.map((startup: Client) => ({
        label: startup.client_name,
        icon:
          impersonatingAccountId === startup.id
            ? faChevronDown
            : faChevronRight,
        url: `/home-${startup.client_name}`,
        subOptions:
          impersonatingAccountId === startup.id ? impersonateMenuOptions : [],
        action: () => {
          if (setimpersonatingAccountId && impersonatingAccountId) {
            setimpersonatingAccountId(null);
          } else {
            onStartupAction(startup.id, startup.client_name, `/home`);
          }
        },
      }));
      setMenuOptions([...startupOptions]);
      if (
        impersonatingAccountId &&
        !auth.authorizedClientIds.includes(impersonatingAccountId) &&
        !auth.isSuperAdmin
      ) {
        clearImpersonationSession();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startups,
    impersonatingAccountId,
    impersonateMenuOptions,
    isLoadingStartups,
    auth,
  ]);

  const resendRequest = async () => {
    await post('investors/authorize-access', {
      clientId: userRequestAccess?.client_id,
      firmId: userRequestAccess?.firm_id,
    })
      .then(() => {
        alertMessageSuccess('An email was sent to the Company you selected!');
        setUserRequestAccess(undefined);
      })
      .catch((ex) => {
        rollbar.error(
          `Error! Email not sent! - details: ${(ex as Error)?.message}`,
          ex as Error
        );
        alertErrorMessage('Error! Email not sent!');
      });
  };

  if (!auth?.isInvestor) {
    return null;
  }

  return (
    <>
      <div className='text-base grow mt-2 mx-3'>
        <div className='font-bold text-base p-2'>Companies</div>
      </div>
      <ul
        className='menu min-w-full overflow-hidden px-1'
        id='MainMenu'
        role='menu'
      >
        {menuOptions.map((option) => (
          <li
            key={option.label}
            className='&:not(:first-child)]:mt-2'
            role='menuitem'
          >
            <MainMenuItem {...option} />
          </li>
        ))}
      </ul>
      <AddNewCompanyButton auth={auth || false} />
      {pendingClientsOptions.length > 0 ? (
        <>
          <div className='text-base grow mt-2 mx-3'>
            <div className='font-bold text-base p-2'>Pending Approvals</div>
          </div>
          <ul
            className='menu min-w-full overflow-hidden px-1'
            id='MainMenu'
            role='menu'
          >
            {pendingClientsOptions.map((option) => (
              <li
                key={option.label}
                className='&:not(:first-child)]:mt-2'
                role='menuitem'
              >
                <MainMenuItem {...option} />
              </li>
            ))}
          </ul>
          {userRequestAccess ? (
            <GenericConfirmationModal
              modalMessage='Are you sure you want to resend the request to this company?'
              showModal={!!userRequestAccess}
              onAcceptAction={() => resendRequest()}
              onCloseAction={() => setUserRequestAccess(undefined)}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
}

export default InvestorsMenu;
