/* eslint-disable @typescript-eslint/no-misused-promises */

import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Input, Select as DaisySelect, Button } from 'react-daisyui';
import { post } from '@/utils/api';
import { validateEmail } from '@/utils/email';
import { JWTUser, JWTFirm } from '@types';
import 'react-toastify/dist/ReactToastify.css';
import Select, { SingleValue } from 'react-select';
import { debounce } from 'lodash';
import { fetcher } from '@/utils/queries';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { useRollbar } from '@rollbar/react';
import { GenericModal } from '@/components/Modal';

interface SelectOptions {
  id: number;
  label: string;
  client_name?: string;
}

interface ClientSimpleDTO {
  id: number;
  client_name: string;
  email_address: string;
}

export default function AddingCompanyModal({
  showModalStatus = false,
  onClose,
  auth,
}: {
  showModalStatus: boolean;
  onClose: () => void;
  auth: JWTUser;
}) {
  const firms = useMemo(() => auth?.firms || [], [auth]);
  const rollbar = useRollbar();
  const [showModal, setShowModal] = useState(showModalStatus);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [email, setEmail] = useState<string>();
  const [searchMap, setSearchMap] = useState<SelectOptions[]>([]);
  const [selectedClient, setSelectedClient] = useState<
    SingleValue<SelectOptions> | undefined
  >();
  const [selectedFirm, setSelectedFirm] = useState<JWTFirm | undefined>(
    firms.length === 1 ? firms[0] : undefined
  );
  const clients = useRef<ClientSimpleDTO[]>([]);

  const emailInputRef = useRef<HTMLInputElement>(null);

  const { Option } = DaisySelect;

  const verifyEmail = (newQuery: string) => {
    if (newQuery.length === 0) setErrorMessage('');
    if (newQuery.length > 4) {
      if (!validateEmail(newQuery))
        setErrorMessage('Error! you should provide a valid email');
      else {
        setErrorMessage('');
        setEmail(newQuery);
      }
    } else {
      setErrorMessage('');
      setEmail(newQuery);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedEmailChange = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      const newQuery = event.target.value;
      verifyEmail(newQuery);
    }, 500),
    []
  );

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    verifyEmail(newEmail);
  };

  const clearInput = () => {
    if (emailInputRef.current) {
      emailInputRef.current.value = '';
    }
    setErrorMessage('');
  };

  const handleSubmit = async () => {
    let url = '';
    let body = {};

    if (selectedClient) {
      url = 'investors/authorize-access';
      body = {
        clientId: selectedClient?.id,
        firmId: selectedFirm?.id,
      };
    } else {
      url = 'investors/invite-client';
      body = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        emailAddress: (emailInputRef?.current as any)?.value || '',
      };
    }
    await post(url, body)
      .then(() => {
        alertMessageSuccess('An email was sent to the Company you selected!');
        setShowModal(false);
        setSelectedFirm(firms.length === 1 ? firms[0] : undefined);
        setSelectedClient(undefined);
        setShowModal(false);
        onClose();
      })
      .catch((ex) => {
        rollbar.error(
          `Error! Email not sent! - details: ${(ex as Error)?.message}`,
          ex as Error
        );
        alertErrorMessage('Error! Email not sent!');
      });
  };

  useEffect(() => {
    setShowModal(showModalStatus);
    if (!showModalStatus) {
      clearInput();
    }
  }, [showModalStatus]);

  const autocomplateOptions: SelectOptions[] = clients.current.map(
    (client) => ({
      id: client.id || 0,
      label: client.client_name,
      client_name: client.client_name,
    })
  );

  const apiClientsByNameOrEmail = async (newQuery: string) => {
    try {
      const clientsListAPIUrl = '/api/clients/search';
      if (!newQuery) {
        return [];
      }
      const res = await fetcher<ClientSimpleDTO[]>(
        `${clientsListAPIUrl}/${newQuery.toLowerCase()}`
      );
      return res;
    } catch {
      return [];
    }
  };

  const filterClients = async (newQuery: string) => {
    clients.current = await apiClientsByNameOrEmail(newQuery);
    const requestedClients = (auth.user_request_access || []).map(
      (client) => client.client_id
    );
    const searchInputs: SelectOptions[] = clients.current
      .filter(
        (client) =>
          !auth.authorizedClientIds?.includes(client?.id || 0) &&
          !requestedClients?.includes(client?.id || 0)
      )
      .map((client) => ({
        id: client.id || 0,
        label: client.client_name,
        client_name: client.client_name,
      }));
    setSearchMap(searchInputs);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (newQuery: string) => {
      await filterClients(newQuery);
    }, 500),
    []
  );

  const handleSearchQueryChange = useCallback(
    async (newQuery: string) => {
      setSearchQuery(newQuery);
      await debouncedSearch(newQuery);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch, autocomplateOptions]
  );

  const onCloseAction = () => {
    setShowModal(false);
    onClose();
  };

  const checkDisabledConfirmButton = () => {
    if (auth.firms && auth.firms.length > 1 && !selectedFirm) {
      return true;
    }
    if (!selectedClient && !email) {
      return true;
    }
    if (email) return false;

    return false;
  };

  return (
    <GenericModal onClose={onCloseAction} isOpen={showModal}>
      {`To add a Startup enter the founder's work email address below:`}
      <div className='mt-8 flex'>
        <div title='Select Client'>
          {firms.length > 1 ? (
            <>
              <div className='font-bold text-base'>
                Firm requesting reports:
              </div>
              <DaisySelect
                value={selectedFirm ? selectedFirm?.id : 0}
                className='select select-bordered select-accent w-full mt-0'
                onChange={(selectedFirmId: number) => {
                  setSelectedFirm(
                    firms?.find(
                      (firm) => firm.id === Number(selectedFirmId)
                    ) as JWTFirm
                  );
                }}
              >
                <Option value={0} disabled>
                  Select a firm
                </Option>

                {
                  firms?.map((firm) => (
                    <Option
                      key={`firm_${firm.firm_name}`}
                      value={String(firm.id)}
                    >
                      {firm.firm_name}
                    </Option>
                  )) as unknown as ReactElement
                }
              </DaisySelect>
              <br />
              <br />
            </>
          ) : null}
          <h1 className='text-lg font-bold'>Select Company</h1>
          <br />
          <div className='font-bold text-sm w-[100%] text-left'>
            <div className='mb-2'>{`Search existing Company by name or founder's email:`}</div>
            <div data-testid='select-client-add'>
              <Select
                className='react-select-container border-accent mb-2'
                classNamePrefix='react-select'
                closeMenuOnSelect
                isClearable
                options={searchMap}
                inputValue={searchQuery}
                value={selectedClient}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onInputChange={handleSearchQueryChange}
                onChange={(value) => {
                  setSelectedClient(value);
                  setSearchMap([]);
                }}
                filterOption={() => true} // Only filter querying the api
                onFocus={() => setSearchMap([])}
              />
              <br />
              <div className='flex justify-center items-center'>
                <div
                  style={{ borderBottom: '1px solid white', width: '50%' }}
                />
                <span className='px-2'>OR</span>
                <div
                  style={{ borderBottom: '1px solid white', width: '50%' }}
                />
              </div>
              <br />
              <div className='mb-2'>
                Enter email address of new Company you want to invite:
              </div>
            </div>
          </div>
          <Input
            size='md'
            type='email'
            className='border-accent w-full'
            required
            data-testid='working-email-input'
            ref={emailInputRef}
            placeholder='Work email'
            onBlur={handleEmailChange}
            onChange={debouncedEmailChange}
            disabled={!!selectedClient}
          />
          {errorMessage.length > 0 ? (
            <div role='alert' className='alert alert-error mt-2'>
              <span>{errorMessage}</span>
            </div>
          ) : null}
        </div>
      </div>
      <div className='mt-8 flex justify-end'>
        <Button
          color='accent'
          variant='outline'
          size='sm'
          onClick={onCloseAction}
        >
          Cancel
        </Button>
        <Button
          color='accent'
          className='ml-2'
          size='sm'
          onClick={async () => {
            await handleSubmit();
          }}
          disabled={errorMessage.length > 1 || checkDisabledConfirmButton()}
        >
          Confirm
        </Button>
      </div>
    </GenericModal>
  );
}
