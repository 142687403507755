import { DashboardCard } from '@/components/DashboardCard';
import type { RootFolder, EventLog, File, Folder } from '@types';
import { Alert, Menu, Card } from 'react-daisyui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { FolderOrFileComponent } from '@/components/FileBrowser/components/FileDisplay';

import { get } from '@/utils/queries';

import { Spinner } from '@/components/Spinner';
import moment from 'moment';
import { useMemo } from 'react';
import { getMostRecentFiles } from '@/components/FilesCard/utils';
import { MAX_RECENT_FILES } from '@/components/FilesCard/constants';
import useFirms from '@/hooks/useFirms';

interface EventLogsFiles {
  logDownloadedDocs: EventLog[];
  logDownloadedDocsCount: number;
}

const formatDate = (date: Date) => {
  return moment(date).format('YYYY-MM-DD');
};

const formatEventsToFiles = (event: EventLog): File => {
  const fileDownloaded: File = {
    type: 'file',
    id: event?.extra_data?.file_id || '',
    teamId: event?.extra_data?.file_teamId || '',
    name:
      event.extra_data?.downloaded_file ||
      event.extra_data?.uploaded_file ||
      '',
    created_at: event.extra_data?.date_and_time || '',
    updated_at: formatDate(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      event.human_readable!
    ).toLocaleString(),
  };
  return fileDownloaded;
};

export default function FilesEventCard({ clientId }: { clientId: number }) {
  const { useGetFirmsClients } = useFirms();
  const { data, isLoading } = get<EventLogsFiles>(`logs/files/${clientId}`);
  const { data: files, isLoading: isLoadingFiles } = get<RootFolder>('files');
  const { data: firmsClientsData } = useGetFirmsClients(clientId);

  const downloadedFiles: File[] =
    data?.logDownloadedDocs.map(formatEventsToFiles) || [];

  const recentFiles = useMemo(() => {
    if (!files) return [];

    return getMostRecentFiles(files as Folder, MAX_RECENT_FILES);
  }, [files]);

  if (!data && !isLoading) return null;

  if (isLoading || isLoadingFiles) {
    return (
      <Card.Body className='w-full h-32 flex items-center justify-center'>
        <Spinner className='opacity-50' />
      </Card.Body>
    );
  }

  return (
    <DashboardCard data-testid='last-account-file'>
      <div className='flex'>
        <div className='w-1/2' data-testid='files-event-card'>
          <div className='flex items-center'>
            <FontAwesomeIcon
              icon={faFolderOpen}
              className='mr-5 text-accent'
              size='2x'
            />
            <h3 className='font-bold text-base'>Recently Uploaded</h3>
          </div>
          {recentFiles.length > 0 && (
            <Menu className='overflow-y-auto'>
              {recentFiles.map((file) => (
                <FolderOrFileComponent
                  key={file.id}
                  item={file}
                  showLocation={false}
                  firmClientsData={firmsClientsData}
                />
              ))}
            </Menu>
          )}
          {downloadedFiles.length === 0 && (
            <Alert
              className='rounded-2xl opacity-60 text-sm text-left w-full mt-4'
              icon={<ExclamationCircleIcon className='w-8 h-8' />}
              status='warning'
            >
              <div>There are no files uploaded recently.</div>
            </Alert>
          )}
        </div>
        <div className='w-1/2 ml-8' data-testid='files-event-card'>
          <div className='flex items-center'>
            <FontAwesomeIcon
              icon={faFolderOpen}
              className='mr-5 text-accent'
              size='2x'
            />
            <h3 className='font-bold text-base'>Recently Downloaded</h3>
          </div>
          {downloadedFiles.length > 0 && (
            <Menu className='overflow-y-auto'>
              {downloadedFiles.map((file) => (
                <FolderOrFileComponent
                  key={file.id}
                  item={file}
                  showLocation={false}
                  firmClientsData={firmsClientsData}
                />
              ))}
            </Menu>
          )}
          {downloadedFiles.length === 0 && (
            <Alert
              className='rounded-2xl opacity-60 text-sm text-left w-full mt-4'
              icon={<ExclamationCircleIcon className='w-8 h-8' />}
              status='warning'
            >
              <div>There are no files downloaded recently.</div>
            </Alert>
          )}
        </div>
      </div>
    </DashboardCard>
  );
}
