import { InvestorReportCardDTO, File } from '@types';
import { Button, Menu } from 'react-daisyui';
import {
  checkIfFileShouldBePreviewed,
  DocIcon,
} from '@/components/FileBrowser/utils';
import { fetchUrl, processDownloadUrl } from '@/utils/teamsFileDownload';
import { useRollbar } from '@rollbar/react';
import { ResponseTypes } from '../../../../../../shared/constants';

export function InvestorReportCardCompleted({
  investorReportsCard,
  setFileToPreview,
}: {
  investorReportsCard: InvestorReportCardDTO;
  setFileToPreview: (value: File) => void;
}) {
  const rollbar = useRollbar();

  const downloadFile = (
    fileId: string,
    fileName: string,
    fileTeamId: string
  ) => {
    fetchUrl(fileId, fileTeamId)
      .then(async (url) => {
        await processDownloadUrl(url, fileName);
        // await logEvent(file, investorReportsCard.investor_report_id, auth?.role as string);
      })
      .catch((err) => rollbar.log(err as Error));
  };

  const handleClickFile = () => {
    if (
      checkIfFileShouldBePreviewed(
        investorReportsCard.response?.fileName as string
      )
    ) {
      const file = {
        id: investorReportsCard.response?.fileId,
        type: 'file',
        name: investorReportsCard.response?.fileName as string,
        teamId: investorReportsCard.response?.teamId as string,
        created_at: '',
        updated_at: '',
      } as File;
      setFileToPreview(file);
    } else {
      downloadFile(
        investorReportsCard.response?.fileId as string,
        investorReportsCard.response?.fileName as string,
        investorReportsCard.response?.teamId as string
      );
    }
  };

  const openExternalFile = (externalLink: string) => {
    window.open(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      externalLink,
      '_blank',
      'noreferrer'
    );
  };

  return (
    <>
      {investorReportsCard.response?.temp_file_storage_id ? (
        <>
          <div>
            <span className='px-4'>File:</span>
            <span className='font-bold'>
              {' '}
              {investorReportsCard.response.fileName}
            </span>
          </div>
          <div className='py-2'>
            <span className='px-4'>Status:</span>
            <span> Pending upload</span>
          </div>
        </>
      ) : null}
      {(investorReportsCard.response?.type === ResponseTypes.newFile ||
        investorReportsCard.response?.type === ResponseTypes.existingFile) &&
      investorReportsCard.response?.fileId ? (
        <Menu data-testid='file-browser-list' className='px-4'>
          <Menu.Item className='w-full flex justify-center'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */}
            <a
              // eslint-disable-next-line max-len
              className='flex items-start justify-start text-left w-full no-underline focus:outline-none hover:no-underline'
              data-testid='file-item'
              type='button'
              onClick={() => handleClickFile()}
              style={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                height: '3rem',
              }}
            >
              <DocIcon
                fileName={investorReportsCard.response.fileName as string}
              />

              <div className='min-w-0 md:w-[75%]'>
                {/* eslint-disable-next-line max-len */}
                <div className='font-medium mb-1 w-32 sm:w-auto overflow-hidden  overflow-ellipsis sm:overflow-clip whitespace-nowrap'>
                  {investorReportsCard.response.fileName as string}
                </div>
              </div>
            </a>
          </Menu.Item>
        </Menu>
      ) : null}
      {investorReportsCard.response?.type === ResponseTypes.externalFile ? (
        <div className='px-4'>
          <Button
            className='mr-3'
            color='accent'
            data-testid='hubspot-button'
            variant='outline'
            size='sm'
            onClick={() => {
              openExternalFile(
                investorReportsCard.response?.externalUrl as string
              );
            }}
          >
            Open external file
          </Button>
        </div>
      ) : null}
    </>
  );
}
