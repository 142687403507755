/* eslint-disable react/destructuring-assignment */
import { InvestorReportDTO, InvestorReportTemplateDTO } from '@types';
import { Button } from 'react-daisyui';
import { Column } from '@/components/Table/Table';
import { DateTime } from 'luxon';
import {
  findTemplateForReport,
  formatDate,
  getStatusColor,
  InvestorReportingTableData,
} from '../../utils/investorReports';
import { ReportsFrequencies } from '../../../../../../shared/constants';

export const columnsForClientsView: Column<InvestorReportingTableData>[] = [
  {
    header: 'investor name',
    accessor: 'investor_name',
    sortable: true,
    Cell: (row) => (
      <div role='button' tabIndex={0}>
        {row.investor_name}
      </div>
    ),
  },
  {
    header: 'Status',
    accessor: 'status',
    sortable: true,
    Cell: (row: InvestorReportingTableData) => (
      <div className={`w-4 h-4 rounded-full circle-${getStatusColor(row)}`} />
    ),
  },
  { header: 'Frequency', accessor: 'frequency', sortable: true },
  {
    header: 'Last Investor Update',
    accessor: 'last_investor_update',
    sortable: true,
  },
  {
    header: 'Date of Next Request',
    accessor: 'next_request_date',
    sortable: true,
  },
];

export const columnsForInvestorView = (
  openNudgeModal: (row: InvestorReportingTableData) => void,
  includePeriodColumn: string | null
): Column<InvestorReportingTableData>[] => {
  const columns: Column<InvestorReportingTableData>[] = [
    {
      header: 'Company Name',
      accessor: 'company_name',
      sortable: true,
      Cell: (row: InvestorReportingTableData) => (
        <div className='flex items-center'>
          <span>{row.company_name}</span>
          {row.status === 'overdue' &&
            row.investor_report_nudge &&
            row.investor_report_nudge.length === 0 && (
              <Button
                color='accent'
                size='xs'
                className='ml-3 rounded-xl'
                onClick={() => openNudgeModal(row)}
              >
                Nudge
              </Button>
            )}
        </div>
      ),
    },
    {
      header: 'Firm',
      accessor: 'firm_name',
      sortable: true,
    },
    {
      header: 'Date of Next Request',
      accessor: 'next_request_date',
      sortable: true,
    },
    {
      header: 'Last Investor Update',
      accessor: 'last_investor_update',
      sortable: true,
    },
  ];

  if (includePeriodColumn) {
    columns.push({
      header: 'Period',
      accessor: 'period',
      sortable: true,
    });
  }

  return columns;
};

const resolveNextRequestDate = (
  report: InvestorReportDTO,
  template: InvestorReportTemplateDTO | undefined
) => {
  const now = DateTime.now();
  const date = DateTime.fromISO(report.created_at as unknown as string);
  switch (report.frequency) {
    case ReportsFrequencies.monthly: {
      if (!template) return ' - ';
      const currentStartingDate = DateTime.local(
        now.year,
        now.month,
        template?.starting_date || 1
      );
      const createdAt = DateTime.fromISO(
        report.created_at as unknown as string
      );
      let nexRequestDate = '';
      if (currentStartingDate > createdAt) {
        nexRequestDate = currentStartingDate.toFormat('MM/dd/yyyy');
      } else {
        nexRequestDate = currentStartingDate
          .plus({ month: 1 })
          .toFormat('MM/dd/yyyy');
      }
      return nexRequestDate;
    }

    case ReportsFrequencies.quarterly: {
      const currentQuarter = Math.ceil(date.month / 3);

      const currentQuarterStart = DateTime.fromObject({
        year: date.year,
        month: (currentQuarter - 1) * 3 + 1,
        day: 1,
      }).startOf('day');

      const nextQuarterStart = currentQuarterStart.plus({ months: 3 });
      return nextQuarterStart.toFormat('MM/dd/yyyy');
    }

    case ReportsFrequencies.annual: {
      const currentYearStart = DateTime.fromObject({
        year: date.year,
        month: 1,
        day: 1,
      }).startOf('day');

      const nextYearStart = currentYearStart.plus({ years: 1 });
      return nextYearStart.toFormat('MM/dd/yyyy');
    }

    case ReportsFrequencies.oneTime:
      return ' - ';

    default:
      return ' - ';
  }
};

export const mapApiInvestorReportsDateToTableData = (
  values: InvestorReportDTO[],
  investorReportTemplates: InvestorReportTemplateDTO[]
) => {
  return values.map((value) => {
    const template = findTemplateForReport(investorReportTemplates, value);
    return {
      ...value,
      id: value.id,
      company_name: value.client?.client_name || '',
      investor_name: value.firm
        ? value.firm.firm_name
        : `${value.user?.first_name as string} ${
            value.user?.last_name as string
          }`,
      last_investor_update:
        formatDate(value.created_at as unknown as string) || '',
      next_request_date: resolveNextRequestDate(value, template),
      firm_name: value.firm?.firm_name || '',
      period: value.period,
    };
  });
};
