import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import { faChartLine, faRefresh } from '@fortawesome/free-solid-svg-icons';
import PageContainer from '@/components/PageContainer';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import { useState } from 'react';
import { GenericModal } from '@/components/Modal';
import { Auth, IUserContext, useUserContext } from '@/context/UserContext';
import { Client, InvestorReportDTO, InvestorReportTemplateDTO } from '@types';
import { get } from '@/utils/queries';
import { LoadingPage } from '@/pages/LoadingPage';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';
import { get as getApi } from '@/utils/api';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import FeatureToggle from '@/components/FeatureToggle';
import { InvestorReportingManagement } from './InvestorReportingManagement';
import RequestBuilder from './components/modals/RequestBuilder';

const templateURL = (
  auth: Auth,
  impersonatingAccountId: number | null
): string => {
  const basePath = 'investorReports/templates';

  if (auth.isInvestor) return basePath;

  if (auth.isClient) {
    const clientId = auth.clients?.[0]?.id;
    if (!clientId) {
      throw new Error('Client ID is missing for client user');
    }
    return `${basePath}/client/${clientId}`;
  }

  if (auth.isAdmin) {
    if (!impersonatingAccountId) {
      throw new Error('Impersonating account ID is required for admin');
    }
    return `${basePath}/client/${impersonatingAccountId}`;
  }

  return basePath;
};

function InvestorReporting() {
  const [showRequestBuilderModal, setShowRequestBuilderModal] = useState(false);

  const { auth, companyName, impersonatingAccountId }: IUserContext =
    useUserContext();
  const {
    data: investorReports,
    isValidating: isLoadingReports,
    error: reportsError,
    mutate: mutateReports,
  } = get<InvestorReportDTO[]>(
    impersonatingAccountId
      ? `investorReports?clientId=${impersonatingAccountId}`
      : 'investorReports'
  );

  const {
    data: allInvestorReports,
    isValidating: isLoadingAllReports,
    error: allReportsError,
  } = get<InvestorReportDTO[]>(
    impersonatingAccountId
      ? `investorReports/all?clientId=${impersonatingAccountId}`
      : 'investorReports/all'
  );

  const {
    data: investorReportTemplates,
    isValidating: isLoadingReportTemplates,
    error: reportTemplatesError,
  } = get<InvestorReportTemplateDTO[]>(
    templateURL(auth as Auth, impersonatingAccountId)
  );

  const { data: companyData } = auth?.isClient
    ? get<Client[]>(`account/${auth.clients[0].id}`)
    : get<Client[]>('account/list');

  // #region SC-2794 - Trigger report creation from template
  const triggerGenerateReports = async () => {
    try {
      await getApi('investorReports/createReportsFromTemplate');
      alertMessageSuccess(
        'Report generation in progress. Please wait a few minutes and then refresh the tab.'
      );
    } catch (err) {
      alertErrorMessage('Error generating reports.');
    }
  };
  // #endregion

  if (
    (!investorReports && isLoadingReports) ||
    (!allInvestorReports && isLoadingAllReports) ||
    (!investorReportTemplates && isLoadingReportTemplates)
  ) {
    return <LoadingPage hasNav />;
  }

  if (reportsError || allReportsError || reportTemplatesError) {
    return (
      <PageContainer name='client-list'>
        <DashboardWrapper>
          <ErrorAlert
            isSuperAdmin={auth?.isSuperAdmin}
            error={reportsError as unknown}
          />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  const props = {
    ...(companyName && { companyName }),
    ...(companyData && { companyData }),
  };

  return (
    <PageContainer name='investor-account'>
      <DashboardWrapper {...props}>
        <div className='p-4'>
          <div className='flex w-full justify-between items-center mb-4'>
            <h1 className='text-lg font-bold uppercase'>
              <FontAwesomeIcon
                icon={faChartLine}
                size='lg'
                className='mr-4 text-white'
              />
              Investor Reporting
            </h1>
            {auth?.isInvestor ? (
              <div className='text-lg font-bold uppercase text-right flex'>
                {/* #region SC-2794 - Trigger report creation from template */}
                <FeatureToggle featureTag='generate-reports' filterByRole>
                  <Button
                    color='accent'
                    size='sm'
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async () => {
                      await triggerGenerateReports();
                    }}
                    className='mr-2'
                  >
                    <FontAwesomeIcon icon={faRefresh} className='mr-1' />
                    Generate reports
                  </Button>
                </FeatureToggle>
                {/* #endregion */}
                <Button
                  color='accent'
                  size='sm'
                  onClick={() => {
                    setShowRequestBuilderModal(true);
                  }}
                >
                  Request builder
                </Button>
              </div>
            ) : null}
          </div>
        </div>

        {auth?.isInvestor ? (
          <GenericModal
            isOpen={showRequestBuilderModal}
            onClose={() => setShowRequestBuilderModal(false)}
            className='max-w-[35%]'
          >
            <RequestBuilder
              onSave={async () => {
                setShowRequestBuilderModal(false);
                await mutateReports();
              }}
            />
          </GenericModal>
        ) : null}
        <InvestorReportingManagement
          investorReports={investorReports || []}
          allInvestorReports={allInvestorReports || []}
          investorReportTemplates={investorReportTemplates || []}
        />
      </DashboardWrapper>
    </PageContainer>
  );
}

export default InvestorReporting;
