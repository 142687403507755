import { GenericModal } from '@/components/Modal';
import { Button } from 'react-daisyui';
import { useMemo, useState } from 'react';
import { useRollbar } from '@rollbar/react';
import { Spinner } from '@/components/Spinner';
import { login } from '@/utils';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import type { FileItem, FirmData, JWTUser } from '@types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FileSearchItem } from '@/components/FileBrowser/types';
import useUsersList from '@/hooks/useUsersList';
import useFirms from '@/hooks/useFirms';

interface Option {
  value: string;
  id: number;
}
const optionNew = { value: 'Notify only newly-added users/firms', id: 1 };
const optionAll = { value: 'Notify all users/firms who have access', id: 2 };
const optionSelect = { value: 'Select users/firms to notify', id: 3 };
const options = [optionNew, optionAll, optionSelect];

export interface SelectValue {
  value: number;
  label: string;
  email: string;
}

interface Props {
  auth: JWTUser;
  currentPath: string;
  clientId: number;
  isOpen: boolean;
  name: string;
  onClose: () => void;
  item: FileItem | FileSearchItem;
  itemType: 'folder' | 'file';
  newUsers: SelectValue[];
  newFirms: FirmData[];
}

export default function ShareNotifications({
  auth,
  isOpen,
  onClose,
  currentPath,
  clientId,
  name,
  item,
  itemType,
  newUsers,
  newFirms,
}: Props) {
  const { useGetFirmsClients } = useFirms();
  const { getUsersListByClientId } = useUsersList();
  const { data: firmsClientsData, isLoading: isLoadingFirms } =
    useGetFirmsClients(clientId);
  const { data: clientUsersData, isLoading } = getUsersListByClientId(clientId);
  const rollbar = useRollbar();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<SelectValue[]>([]);
  const [selectedFirms, setSelectedFirms] = useState<SelectValue[]>([]);
  // eslint-disable-next-line max-len
  const content = `${auth.first_name} ${auth.last_name} has shared ${name} with you via OpStart. Click the link below to login and view.`;
  const [selectedOption, setSelectedOption] = useState<Option>(options[0]);

  const usersList = useMemo(() => {
    const users =
      item.permissions?.flatMap((p) => {
        return p.allowedUsers;
      }) || [];
    const usersWithPermissions =
      clientUsersData
        ?.filter((u) => users.includes(u.id) && u.firms?.length === 0)
        .map((u) => {
          return {
            value: u.id,
            label: `${u.first_name} ${u.last_name}`,
            email: u.email_address,
          } as SelectValue;
        }) || [];
    setSelectedUsers([...newUsers]);
    const newList = [...usersWithPermissions, ...newUsers];
    const uniqueItems = Array.from(
      new Map(newList.map((i) => [i.value, i])).values()
    );
    return uniqueItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientUsersData, newUsers]);

  const newFirmsSelectValue = useMemo(() => {
    const newFirmsIds = newFirms.map((f) => f.firmId);
    return (
      firmsClientsData
        ?.filter((fc) => newFirmsIds.includes(fc.firm_id as number))
        .map((fc) => {
          return {
            value: fc.firm_id as number,
            label: fc.firm?.firm_name as string,
            email: '',
          } as SelectValue;
        }) || []
    );
  }, [firmsClientsData, newFirms]);

  const firmsList = useMemo(() => {
    const firms =
      item.permissions?.flatMap((p) => {
        return p.allowedFirms;
      }) || [];
    const firmsWithPermissions =
      firmsClientsData
        ?.filter((fc) => firms.includes(fc.firm_id as number))
        .map((fc) => {
          return {
            value: fc.firm_id as number,
            label: fc.firm?.firm_name as string,
            email: '',
          } as SelectValue;
        }) || [];
    setSelectedFirms(newFirmsSelectValue);
    const newList = [...firmsWithPermissions, ...newFirmsSelectValue];
    const uniqueItems = Array.from(
      new Map(newList.map((i) => [i.value, i])).values()
    );
    return uniqueItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientUsersData, newUsers, firmsClientsData, newFirmsSelectValue]);

  const handleOptionChange = (id: number) => {
    const optionValue = options.filter((o) => o.id === id)[0];
    switch (optionValue) {
      case optionNew:
        setSelectedUsers([...newUsers]);
        setSelectedFirms([...newFirmsSelectValue]);
        break;
      case optionAll:
        setSelectedUsers(usersList);
        setSelectedFirms(firmsList);
        break;
      case optionSelect:
        break;
      default:
        break;
    }
    setSelectedOption(optionValue);
  };

  const handleSelectUser = (user: SelectValue) => {
    setSelectedOption(optionSelect);
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(user)
        ? prevSelectedUsers.filter((prevUser) => prevUser !== user)
        : [...prevSelectedUsers, user]
    );
  };

  const handleSelectFirm = (firm: SelectValue) => {
    setSelectedOption(optionSelect);
    setSelectedFirms((prevSelectedFirms) =>
      prevSelectedFirms.includes(firm)
        ? prevSelectedFirms.filter((prevFirm) => prevFirm !== firm)
        : [...prevSelectedFirms, firm]
    );
  };

  const getRecipients = () => {
    const firms =
      firmsClientsData?.filter((f) =>
        selectedFirms.map((s) => s.value).includes(f.firm_id as number)
      ) || [];
    const firmRecipients = firms.flatMap((f) => {
      const primaryUsers = f.firm?.firm_users?.filter((fu) => fu.primary) || [];
      if (primaryUsers && primaryUsers.length > 0) {
        return primaryUsers.map((fu) => {
          const userData = clientUsersData?.find((u) => u.id === fu.user_id);
          return {
            value: fu.user_id as number,
            label: `${userData?.first_name as string} ${
              userData?.last_name as string
            }`,
            email: userData?.email_address as string,
          };
        });
      }
      const firstUser =
        f.firm?.firm_users?.sort(
          (a, b) =>
            (a.created_at || new Date()).getTime() -
            (b.created_at || new Date()).getTime()
        ) || [];
      if (firstUser && firstUser.length > 0) {
        const userData = clientUsersData?.find(
          (u) => u.id === firstUser[0].user_id
        );
        return [
          {
            value: f.firm_id as number,
            label: `${userData?.first_name as string} ${
              userData?.last_name as string
            }`,
            email: userData?.email_address as string,
          },
        ];
      }
      return [];
    });
    return firmRecipients;
  };

  const onSendNotifications = async () => {
    setIsSaving(true);
    const subject = `${auth.first_name} ${auth.last_name} has shared a ${itemType} with you`;
    const type = 'shareFolder';
    const redirectUrl = `${window.location.origin}/files/${currentPath}`;

    const invalidUserMessage = 'Invalid User';
    let error = false;

    const firmRecipients = getRecipients();
    const recipientsList = [...selectedUsers, ...firmRecipients];
    try {
      for (const user of recipientsList) {
        if (!error) {
          // eslint-disable-next-line no-await-in-loop
          const res = await login(
            user.email.toLowerCase(),
            subject,
            type,
            redirectUrl,
            content
          );
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
          if ((res as any).success) {
            rollbar.debug(`Magic link sent to  ${user.label.toLowerCase()}`);
            alertMessageSuccess('Link Sent Successfully');
          } else {
            rollbar.error(
              `ShareFolder.tsx - onSave - Error trying to send the magic link for user ${user.label.toLowerCase()}`,
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
                error: (res as any).error,
              }
            );
            alertErrorMessage('Error sending task reminder');
            error = true;
          }
        }
      }

      setIsSaving(false);
      setSelectedUsers([]);
      onClose();
    } catch (err) {
      if ((err as Error).message.includes('Forbidden'))
        alertErrorMessage(invalidUserMessage);
      setIsSaving(false);
    }
  };
  const getOptionIcon = (option: Option) =>
    selectedOption === option ? faSquareCheck : faSquare;
  const getUsersIcon = (user: SelectValue) =>
    selectedUsers.includes(user) ? faSquareCheck : faSquare;
  const getFirmsIcon = (firm: SelectValue) =>
    selectedFirms.includes(firm) ? faSquareCheck : faSquare;

  if (isLoading || isLoadingFirms) {
    return <Spinner className='mx-auto w-16 mt-10' />;
  }

  return (
    <GenericModal isOpen={isOpen} onClose={onClose}>
      <div className='flex h-full flex-col'>
        <div className='flex items-center'>
          <div className='w-full'>
            <div className='font-bold text-base'>Send Email Notification</div>
            <br />

            {options?.map((option) => (
              <div key={`div-main-option-${option.id}`}>
                <div
                  className='flex items-start'
                  key={`div-sub-option-${option.id}`}
                  id={`div-sub-option-${option.id}`}
                >
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <label
                    className='flex items-center cursor-pointer'
                    htmlFor='enable-tab'
                    key={`label-tab-option-${option.id}`}
                    id={`label-tab-option-${option.id}`}
                    onClick={() => handleOptionChange(option.id)}
                  >
                    <FontAwesomeIcon
                      icon={getOptionIcon(option)}
                      size='xl'
                      data-testid={`checkbox-option-${option.id}`}
                    />
                    <h3
                      key={`option-name-${option.id}`}
                      id={`option-name-${option.id}`}
                      className='text-base ml-2 break-all max-w-xs'
                    >
                      {option.value}
                    </h3>
                  </label>
                </div>
                <br />
              </div>
            ))}

            {firmsList.length > 0 ? (
              <h3 className='mb-3 font-bold'>Firms</h3>
            ) : null}
            {firmsList.map((firm) => (
              <div key={`div-main-firm-${firm.value}`}>
                <div
                  className='flex items-start'
                  key={`div-sub-firm-${firm.value}`}
                  id={`div-sub-firm-${firm.value}`}
                >
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <label
                    className='flex items-center cursor-pointer'
                    htmlFor='enable-tab'
                    key={`label-tab-firm-${firm.value}`}
                    id={`label-tab-firm-${firm.value}`}
                    onClick={() => handleSelectFirm(firm)}
                  >
                    <FontAwesomeIcon
                      icon={getFirmsIcon(firm)}
                      size='xl'
                      className={`px-2 ${
                        getFirmsIcon(firm) === faSquare
                          ? 'grey-icon'
                          : 'text-blue-300'
                      }`}
                      data-testid={`checkbox-user-${firm.value}`}
                    />
                    <h3
                      key={`user-name-${firm.value}`}
                      id={`user-name-${firm.value}`}
                      className='text-base ml-2 break-all max-w-xs'
                    >
                      {firm.label}
                    </h3>
                  </label>
                </div>
                <br />
              </div>
            ))}

            {usersList.length > 0 ? (
              <h3 className='mb-3 font-bold'>Individual Users</h3>
            ) : null}
            {usersList.map((user) => (
              <div key={`div-main-user-${user.value}`}>
                <div
                  className='flex items-start'
                  key={`div-sub-user-${user.value}`}
                  id={`div-sub-user-${user.value}`}
                >
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <label
                    className='flex items-center cursor-pointer'
                    htmlFor='enable-tab'
                    key={`label-tab-user-${user.value}`}
                    id={`label-tab-user-${user.value}`}
                    onClick={() => handleSelectUser(user)}
                  >
                    <FontAwesomeIcon
                      icon={getUsersIcon(user)}
                      size='xl'
                      className={`px-2 ${
                        getUsersIcon(user) === faSquare
                          ? 'grey-icon'
                          : 'text-blue-300'
                      }`}
                      data-testid={`checkbox-user-${user.value}`}
                    />
                    <h3
                      key={`user-name-${user.value}`}
                      id={`user-name-${user.value}`}
                      className='text-base ml-2 break-all max-w-xs'
                    >
                      {user.label}
                    </h3>
                  </label>
                </div>
                <br />
              </div>
            ))}
          </div>
        </div>
        <br />
        <br />
        <div className='flex justify-end'>
          <Button
            title="don't send"
            color='ghost'
            size='sm'
            className='mr-4'
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onClose}
            data-testid='dont-send-notification'
          >
            {`Don't Send`}
            {isSaving && <Spinner className='mx-auto w-4 text-base ml-4' />}
          </Button>
          <Button
            type='submit'
            title='send notification'
            color='accent'
            size='sm'
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSendNotifications}
            disabled={selectedFirms.length === 0 && selectedUsers.length === 0}
            data-testid='send-notification'
          >
            Send Notification
            {isSaving && <Spinner className='mx-auto w-4 text-base ml-4' />}
          </Button>
        </div>
      </div>
    </GenericModal>
  );
}
