import { useLocation, useNavigate } from 'react-router-dom';
import { DashboardCard } from '@/components/DashboardCard';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InvestorReportDTO, InvestorReportTemplateDTO } from '@types';
import { useState, useMemo } from 'react';
import { Input, Button } from 'react-daisyui';
import InvestorClientList from '@/pages/InvestorReporting/components/Investors/InvestorClientList';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { InvestorReportsListForClients } from './components/Clients/InvestorReportsListForClients';

interface Props {
  investorReports: InvestorReportDTO[];
  allInvestorReports: InvestorReportDTO[];
  investorReportTemplates: InvestorReportTemplateDTO[];
}

export function InvestorReportingManagement({
  investorReports,
  allInvestorReports,
  investorReportTemplates,
}: Props) {
  const { auth, impersonatingAccountId }: IUserContext = useUserContext();
  const [searchText, setSearchText] = useState<string>('');
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(40);
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const clientNameFromQuery = queryParams.get('clientName') || '';
  const showAllReports = queryParams.get('showAllReports');

  const filteredReports = useMemo(() => {
    const reports = showAllReports ? allInvestorReports : investorReports;
    return reports.filter((report) => {
      const matchesClientName = clientNameFromQuery
        ? report.client?.client_name
            ?.toLowerCase()
            .includes(clientNameFromQuery.toLowerCase())
        : true;

      const matchesSearchText = report.client?.client_name
        ?.toLowerCase()
        .includes(searchText.toLowerCase());

      return matchesClientName && matchesSearchText;
    });
  }, [
    investorReports,
    allInvestorReports,
    showAllReports,
    searchText,
    clientNameFromQuery,
  ]);

  return (
    <DashboardCard className='p-4' cardBodyclassName='p-0' hasMininumWidth>
      <div className='mt-3 mb-1'>
        <div className='relative flex text-gray-600 focus-within:text-gray-400'>
          <div className='flex justify-between w-full'>
            <div className='relative flex'>
              <span className='absolute inset-y-0 left-[260px] flex items-center pl-2'>
                <button
                  type='submit'
                  className='p-1 focus:outline-none focus:shadow-outline'
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </span>
              <Input
                data-testid='client-search-input'
                className='border-accent w-[300px]'
                placeholder='Search Reports'
                size='sm'
                defaultValue={searchText}
                onChange={(event) => setSearchText(event.target.value)}
              />
            </div>

            <div className='ml-auto'>
              {clientNameFromQuery ? (
                <Button
                  color='accent'
                  size='sm'
                  className=''
                  onClick={() => navigate('/i-reporting')}
                >
                  View all reports
                </Button>
              ) : null}
            </div>
          </div>
        </div>

        {auth?.isInvestor ? (
          <InvestorClientList
            investorReports={filteredReports}
            recordsPerPage={recordsPerPage}
            selectedPage={selectedPage}
            onPageSelect={setSelectedPage}
            onSelectRowsChange={setRecordsPerPage}
            searchText={searchText}
            investorReportTemplates={investorReportTemplates}
          />
        ) : null}
        {auth?.isClient || (impersonatingAccountId && auth?.isAdmin) ? (
          <InvestorReportsListForClients
            investorReports={investorReports || []}
            recordsPerPage={recordsPerPage}
            selectedPage={selectedPage}
            onPageSelect={setSelectedPage}
            onSelectRowsChange={setRecordsPerPage}
            searchText={searchText}
            investorReportTemplates={investorReportTemplates}
          />
        ) : null}
      </div>
    </DashboardCard>
  );
}
