import { useNavigate } from 'react-router-dom';
import { faEye, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import {
  InvestorReportsDetailsData,
  findTemplateForReport,
  getCardTitleColor,
} from '@/pages/InvestorReporting/utils/investorReports';
import { Badge, Button } from 'react-daisyui';
import { useMemo, useState, useEffect } from 'react';
import {
  Client,
  InvestorReportCardDTO,
  InvestorReportTemplateDTO,
  File,
  InvestorReportDTO,
} from '@types';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { useRollbar } from '@rollbar/react';
import { InvestorReportAnswer } from '@/pages/InvestorReporting/components/Clients/InvestorReportAnswer';
import { Spinner } from '@/components/Spinner';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import { ToastContainer } from 'react-toastify';
import RequestBuilder from '@/pages/InvestorReporting/components/modals/RequestBuilder';
import { GenericModal } from '@/components/Modal';
import { InvestorReportCardButton } from '@/pages/InvestorReporting/components/InvestorReportCardButton';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { patch } from '@/utils/api';
import { FilePreviewModal } from '@/components/FileBrowser/modals';
import { fetchUrl, processDownloadUrl } from '@/utils/teamsFileDownload';
import useLogEvent from '../../../../utils/logEvent';
import {
  ReportsFrequencies,
  ReportStatuses,
} from '../../../../../../shared/constants';

const finalCardStatus = [ReportStatuses.closed, ReportStatuses.deleted];

export function InvestorReportsDetailedView({
  clientId,
  investorReportsData,
  refreshData,
  refreshTemplates,
  investorReportTemplatesData,
}: {
  clientId: number;
  investorReportsData: InvestorReportsDetailsData;
  refreshData: () => Promise<void>;
  refreshTemplates: () => Promise<void>;
  investorReportTemplatesData: InvestorReportTemplateDTO[];
}) {
  const navigate = useNavigate();
  const rollbar = useRollbar();
  const { auth }: IUserContext = useUserContext();
  const { logEvent } = useLogEvent();
  const [reportId, setReportId] = useState<number | undefined>(
    investorReportsData.selected_investor_report?.id
  );
  const [cardToAnswer, setCardToAnswer] = useState<
    InvestorReportCardDTO | undefined
  >(undefined);
  const [previewFile, setPreviewFile] = useState<boolean>(false);
  const [fileItem, setFileItem] = useState<File | undefined>(undefined);
  const [cardToReopen, setCardToReopen] = useState<
    InvestorReportCardDTO | undefined
  >(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const [isDataRefreshed, setIsDataRefreshed] = useState(false);
  const frequencies = [
    { index: 0, label: ReportsFrequencies.monthly },
    { index: 1, label: ReportsFrequencies.quarterly },
    { index: 2, label: ReportsFrequencies.annual },
    { index: 3, label: ReportsFrequencies.oneTime },
  ];
  const [editTemplate, setEditTemplate] = useState<
    InvestorReportTemplateDTO | undefined
  >(undefined);

  const enabledFrequencies = useMemo(
    () => investorReportsData.investor_reports.map((rep) => rep.frequency),
    [investorReportsData.investor_reports]
  );

  const report = useMemo(() => {
    if (reportId) {
      const selectedReport = investorReportsData.investor_reports.find(
        (rep) => rep.id === reportId
      );
      const template = findTemplateForReport(
        investorReportTemplatesData,
        selectedReport as InvestorReportDTO
      );
      setEditTemplate(template);
      return selectedReport;
    }
    return undefined;
  }, [
    investorReportsData.investor_reports,
    reportId,
    investorReportTemplatesData,
  ]);

  const onChangeFrequency = (frequency: string) => {
    setCardToAnswer(undefined);
    setReportId(
      investorReportsData.investor_reports.find(
        (rep) => rep.frequency === frequency
      )?.id || undefined
    );
  };

  const isReportComplete = useMemo(() => {
    if (report) {
      const isReportNotClosed = report.status !== ReportStatuses.closed;
      const allCardAnswered =
        report.investor_report_cards?.every(
          (card) =>
            card.status === ReportStatuses.completed ||
            card.status === ReportStatuses.closed
        ) || false;
      return isReportNotClosed && allCardAnswered;
    }
    return false;
  }, [report]);

  useEffect(() => {
    if (isDataRefreshed && isReportComplete) {
      setIsDataRefreshed(false);
    }
  }, [isDataRefreshed, isReportComplete]);

  const downloadFile = (file: File) => {
    fetchUrl(file.id, file.teamId)
      .then(async (url) => {
        await processDownloadUrl(url, file.name);
        await logEvent(file, clientId, auth?.role as string);
      })
      .catch((err) => rollbar.log(err as Error));
  };

  const setFileToPreview = (value: File) => {
    setPreviewFile(true);
    setFileItem(value);
  };

  const onHandleCardUpdate = async () => {
    await refreshData();
    setCardToAnswer(undefined);
    setIsDataRefreshed(true);
  };

  const selectCard = (card: InvestorReportCardDTO) => {
    setCardToAnswer(undefined);
    setTimeout(() => {
      setCardToAnswer(card);
    }, 50);
  };

  const handleCardChangeStatus = async (
    card: InvestorReportCardDTO,
    status: ReportStatuses.closed | ReportStatuses.open
  ) => {
    setIsSaving(true);
    try {
      await patch(
        `investorReports/investorReportsCard/client/${clientId}/status/${
          card?.id as number
        }`,
        {
          status,
        }
      );
      alertMessageSuccess('Card updated successfully');
      setCardToReopen(undefined);
      await refreshData();
      setIsDataRefreshed(true);
    } catch {
      alertErrorMessage('Failed to update card, please try again later');
    }
    setIsSaving(false);
  };

  const onHandleReopenCard = async () => {
    await handleCardChangeStatus(
      cardToReopen as InvestorReportCardDTO,
      ReportStatuses.open
    );
  };

  const processFileError = () => {
    alertErrorMessage(
      'This file has been updated. Please wait a few minutes and refresh your page to access the latest version.'
    );
  };

  if (!report) {
    return <span>No report</span>;
  }

  return (
    <div className='flex'>
      <ToastContainer />
      {cardToReopen ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={onHandleReopenCard}
          onCloseAction={() => setCardToReopen(undefined)}
          showModal={!!cardToReopen}
          modalMessage='Are you sure you want to reopen this card?'
        />
      ) : null}
      <div
        className={`${isSaving ? 'spinner-container' : 'hidden'}`}
        id='spinner-total'
        data-testid='spinner-transaction'
      >
        <Spinner className='spinner' />
      </div>
      <div className='w-[50%]'>
        <div className='p-4'>
          Client Name:
          <span className='ml-2 font-bold'>
            {investorReportsData.investor_reports[0].client?.client_name}
          </span>
        </div>
        <div className='pl-4 flex'>
          {frequencies.map((data) => {
            return (
              <span key={`${data.index + 1}_frequency_span`}>
                <Button
                  size='sm'
                  onClick={() => onChangeFrequency(data.label)}
                  className={`${
                    data.label === report.frequency ? 'btn-active' : ''
                  }`}
                  key={`${data.index + 1}_frequency_button`}
                  disabled={!enabledFrequencies.includes(data.label)}
                >
                  {data.label}
                </Button>
              </span>
            );
          })}
        </div>

        <div className='flex'>
          <div className='p-4'>
            Period:
            <span className='ml-2 font-bold'>{report.period}</span>
          </div>
        </div>

        <div className='flex flex-wrap w-full'>
          {report.investor_report_cards?.map((card) => (
            <div
              key={`card-${card.title}`}
              className={`items-center my-2 mx-2 shadow card rounded-lg 
              w-[45%] justify-center bg-gradient-to-r p-[1px] from-[#00a089] to-[#ff0080]`}
            >
              <div
                key={`card-${card.title}`}
                className='bg-[#1b1b1c] shadow-inner rounded h-full w-full content-center'
              >
                <div className='mt-4 mx-4 text-center '>
                  <span className='font-bold'>{card.title}</span>
                  <div>
                    <span className='font-bold text-sm'>
                      Status:
                      <Badge
                        color={getCardTitleColor(card.status)}
                        size='md'
                        className='ml-2'
                      >
                        {card.status}
                      </Badge>
                    </span>
                  </div>
                  <div className='flex justify-center my-2'>
                    {card.status === ReportStatuses.closed &&
                    !finalCardStatus.includes(report.status) ? (
                      <InvestorReportCardButton
                        label='Mark as Closed'
                        icon={faLockOpen}
                        color='error'
                        onClick={() => {
                          setCardToAnswer(undefined);
                          setCardToReopen(card);
                        }}
                        disabled
                        data-testid={`reopen-${card.id as number}`}
                      />
                    ) : null}
                    {card.status === ReportStatuses.completed &&
                    report.status === ReportStatuses.closed ? (
                      <InvestorReportCardButton
                        label='View Response'
                        icon={faEye}
                        color='accent'
                        onClick={() => selectCard(card)}
                        data-testid={`view-response-${card.id as number}`}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='flex flex-wrap w-full mt-4 justify-center'>
          <div className='w-[45%]'>
            <Button
              type='submit'
              title='login'
              color='accent'
              size='sm'
              className='btn btn-accent bg-transparent my-2 mx-4'
              data-testid='send-update-button'
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => {
                navigate(
                  `/i-reporting?clientName=${encodeURIComponent(
                    investorReportsData.investor_reports[0].client
                      ?.client_name || ''
                  )}&showAllReports=true`
                );
              }}
            >
              Click here for Past Updates
            </Button>
          </div>
        </div>
      </div>
      {cardToAnswer ? (
        <GenericModal isOpen onClose={() => {}}>
          <InvestorReportAnswer
            investorReportsCard={cardToAnswer}
            client={report.client as Client}
            setSpinner={(value) => {
              setIsSaving(value);
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onCardUpdated={() => onHandleCardUpdate()}
            setFileToPreview={setFileToPreview}
            isFullWidth
          />
        </GenericModal>
      ) : null}
      {previewFile && fileItem ? (
        <FilePreviewModal
          isOpen={previewFile}
          fileItem={fileItem}
          onClose={() => {
            setPreviewFile(false);
          }}
          onDownload={() => downloadFile(fileItem)}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onError={() => processFileError()}
        />
      ) : null}
      <div className='w-[50%]'>
        <RequestBuilder
          clientId={investorReportsData.selected_investor_report?.client_id}
          onSave={async () => refreshTemplates()}
          editTemplate={editTemplate}
        />
      </div>
    </div>
  );
}
