import { useState, useMemo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { mutate } from 'swr';
import { GenericTable } from '@/components/Table/Table';
import { TableSort } from '@/types';
import { getNewSortStateTable } from '@/utils';
import {
  InvestorListProps,
  InvestorReportingTableData,
} from '@/pages/InvestorReporting/utils/investorReports';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import { post } from '@/utils/queries';
import { InvestorReportDTO } from '@types';
import { alertErrorMessage, alertMessage } from '@/utils/alerts';
import {
  columnsForInvestorView,
  mapApiInvestorReportsDateToTableData,
} from './investorClientList.util';

function InvestorClientList({
  investorReports,
  recordsPerPage,
  selectedPage,
  onPageSelect,
  onSelectRowsChange,
  searchText,
  investorReportTemplates,
}: InvestorListProps) {
  const navigate = useNavigate();
  const [sort, setSort] = useState<TableSort<InvestorReportingTableData>>({
    field: 'action' as keyof InvestorReportingTableData,
    asc: true,
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] =
    useState<InvestorReportDTO | null>(null);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const clientNameFromQuery = queryParams.get('clientName') || '';

  const openNudgeModal = (row: InvestorReportingTableData) => {
    setSelectedReport(row);
    setModalOpen(true);
  };

  // Quick fix to solve issue with the nudge button, should be improved on the future.
  useEffect(() => {}, [isModalOpen]);

  const closeModal = () => {
    setModalOpen(false);
    setSelectedReport(null);
  };

  const sendReminder = async () => {
    if (selectedReport) {
      try {
        await post(`investorReports/nudge/client`, {
          investorReportId: selectedReport.id,
        });
        alertMessage('top-right', 'Reminder sent');
      } catch (error) {
        alertErrorMessage('Failed to send reminder');
      }
      closeModal();
      await mutate('investorReports');
    }
  };

  const sortBy = (fieldName: keyof InvestorReportingTableData) => {
    const newSort = getNewSortStateTable(fieldName, sort);
    setSort(newSort);
  };

  const sortedReports = useMemo(() => {
    const uniqueClients = new Set<string>();
    return investorReports
      .filter((report) =>
        report.client?.client_name
          ?.toLowerCase()
          .includes(searchText.toLowerCase())
      )
      .filter((report) => {
        if (
          !uniqueClients.has(
            `${report.client_id}_${report.firm_id || ''}_${
              report.user_id || ''
            }`
          )
        ) {
          uniqueClients.add(
            `${report.client_id}_${report.firm_id || ''}_${
              report.user_id || ''
            }`
          );
          return true;
        }
        return false;
      });
  }, [investorReports, searchText]);

  const reportData = mapApiInvestorReportsDateToTableData(
    sortedReports,
    investorReportTemplates
  );
  return (
    <div className='mt-4'>
      <GenericTable
        className='w-full mb-2'
        tableName='Investor Clients'
        data={reportData as InvestorReportingTableData[]}
        columns={columnsForInvestorView(openNudgeModal, clientNameFromQuery)}
        sortBy={sortBy}
        sort={sort}
        recordsPerPage={recordsPerPage}
        selectedPage={selectedPage}
        onPageSelect={onPageSelect}
        onSelectRowsChange={onSelectRowsChange}
        onRowClick={(row) => {
          if (!selectedReport) {
            const clientId = row.client_id
              ? encodeURIComponent(row.client_id)
              : '';
            const firmId = row.firm_id ? encodeURIComponent(row.firm_id) : '';
            const userId = row.user_id ? encodeURIComponent(row.user_id) : '';
            const reportId = row.id ? encodeURIComponent(row.id) : '';
            const clientName = encodeURIComponent(clientNameFromQuery);

            if (clientNameFromQuery) {
              navigate(
                // eslint-disable-next-line max-len
                `/i-reporting-details?clientName=${clientName}&clientId=${clientId}&firmId=${firmId}&userId=${userId}&reportId=${reportId}`
              );
            } else {
              navigate(
                `/i-reporting-details?clientId=${clientId}&firmId=${firmId}&userId=${userId}&reportId=${reportId}`
              );
            }
          }
        }}
      />
      <GenericConfirmationModal
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onAcceptAction={sendReminder}
        onCloseAction={closeModal}
        showModal={isModalOpen}
        modalMessage='Send a reminder for the data request?'
      />
    </div>
  );
}

export default InvestorClientList;
