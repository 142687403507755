import { DashboardCard } from '@/components/DashboardCard';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import PageContainer from '@/components/PageContainer';
import { InvestorReportsDetailsData } from '@/pages/InvestorReporting/utils/investorReports';
import { faArrowLeft, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import { InvestorReportsInfo } from '@/pages/InvestorReporting/components/InvestorReportsInfo';
import { Spinner } from '@/components/Spinner';
import { useEffect, useMemo } from 'react';
// eslint-disable-next-line
import { mapApiInvestorReportsDateToTableData } from '@/pages/InvestorReporting/components/Investors/investorClientList.util';
import { Card } from 'react-daisyui';
import useInvestorReportsData from '@/hooks/useInvestorReportsData';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { InvestorReportsDetailedView } from '@/pages/InvestorReporting/components/Investors/InvestorReportDetailedView';

export function InvestorReportsDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { auth, impersonatingAccountId }: IUserContext = useUserContext();

  const queryParams = new URLSearchParams(location.search);
  const firmId = queryParams.get('firmId');
  const userId = queryParams.get('userId');
  const retrievedClientId = queryParams.get('clientId');
  const reportId = queryParams.get('reportId');
  const clientId = queryParams.get('clientId');
  const clientNameFromQuery = queryParams.get('clientName') || '';

  useEffect(() => {
    if (!clientId || !reportId || (!firmId && !userId)) {
      navigate('/i-reporting');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, reportId, firmId, userId]);

  queryParams.delete('firmId');
  queryParams.delete('userId');
  queryParams.delete('clientId');
  queryParams.delete('reportId');
  const newUrl = `${window.location.origin}/i-reporting-details`;
  window.history.replaceState({}, '', newUrl);

  const { getInvestorReportsClientData, getInvestorReportsTemplateData } =
    useInvestorReportsData();
  const data = {
    clientId: Number(retrievedClientId),
    reportId: Number(reportId),
    firmId: firmId ? Number(firmId) : null,
    userId: userId ? Number(userId) : null,
  };

  const {
    data: reportData,
    isLoading,
    error,
    mutate,
  } = getInvestorReportsClientData(
    data?.clientId || 0,
    data?.userId || 0,
    data?.firmId || 0
  );

  const {
    data: reportTemplateData,
    isLoading: isLoadingTemplates,
    error: errorTemplates,
    mutate: mutateTemplates,
  } = getInvestorReportsTemplateData(
    data?.clientId || 0,
    data?.userId || 0,
    data?.firmId || 0
  );

  const refreshData = async () => {
    await mutate();
  };

  const refreshTemplates = async () => {
    await mutateTemplates();
  };

  const investorReportsData: InvestorReportsDetailsData = useMemo(() => {
    if (!reportData || !reportTemplateData) {
      return {
        investor_name: '',
        investor_reports: [],
        selected_investor_report: undefined,
      };
    }
    const mappedData = mapApiInvestorReportsDateToTableData(
      reportData,
      reportTemplateData
    );
    return {
      investor_name: mappedData[0]?.investor_name,
      investor_reports: reportData,
      selected_investor_report: reportData.find(
        (report) => report.id === data.reportId
      ),
    };
  }, [reportData, reportTemplateData, data?.reportId]);

  if (
    (!reportData && !isLoading) ||
    error ||
    (!reportTemplateData && !isLoadingTemplates) ||
    errorTemplates
  ) {
    navigate('/i-reporting');
    return null;
  }

  if (isLoading || isLoadingTemplates) {
    return (
      <Card.Body className='w-full h-32 flex items-center justify-center'>
        <Spinner className='opacity-50' />
      </Card.Body>
    );
  }

  return (
    <PageContainer name='investor-report-details'>
      <DashboardWrapper>
        <>
          <div className='p-4'>
            <div className='flex w-full justify-between items-center mb-4'>
              <h1 className='text-lg font-bold uppercase'>
                <FontAwesomeIcon
                  icon={faChartLine}
                  size='lg'
                  className='mr-4 text-white'
                />
                Investor Reporting Detail
              </h1>
            </div>
          </div>
          <DashboardCard className='p-4' cardBodyclassName='p-0'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
            <div
              className='flex justify-between items-center'
              onClick={() => {
                if (clientNameFromQuery) {
                  // If clientName exists in the query, navigate back with the same filter
                  navigate(
                    `/i-reporting?clientName=${encodeURIComponent(
                      clientNameFromQuery
                    )}`
                  );
                } else {
                  // Otherwise, just navigate back to the generic list view
                  navigate('/i-reporting');
                }
              }}
              role='button'
            >
              <h2 className='text-mg font-bold uppercase flex'>
                <FontAwesomeIcon
                  color='#d3d3d3'
                  icon={faArrowLeft}
                  size='lg'
                  className='p-4 cursor-pointer'
                />
                <span className='items-center content-center'>
                  Go back to list
                </span>
              </h2>
            </div>
            {auth?.isClient || (impersonatingAccountId && auth?.isAdmin) ? (
              <InvestorReportsInfo
                clientId={Number(clientId)}
                investorReportsData={investorReportsData}
                refreshData={refreshData}
              />
            ) : null}
            {auth?.isInvestor ? (
              <InvestorReportsDetailedView
                clientId={Number(clientId)}
                investorReportsData={investorReportsData}
                refreshData={refreshData}
                investorReportTemplatesData={reportTemplateData || []}
                refreshTemplates={refreshTemplates}
              />
            ) : null}
          </DashboardCard>
        </>
      </DashboardWrapper>
    </PageContainer>
  );
}

export default InvestorReportsDetails;
